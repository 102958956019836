
// @ts-nocheck


export const localeCodes =  [
  "en",
  "nl",
  "de",
  "fr",
  "es"
]

export const localeLoaders = {
  "en": [{ key: "../i18n/language/en.ts", load: () => import("../i18n/language/en.ts" /* webpackChunkName: "locale__vercel_path0_i18n_language_en_ts" */), cache: true }],
  "nl": [{ key: "../i18n/language/nl.ts", load: () => import("../i18n/language/nl.ts" /* webpackChunkName: "locale__vercel_path0_i18n_language_nl_ts" */), cache: true }],
  "de": [{ key: "../i18n/language/de.ts", load: () => import("../i18n/language/de.ts" /* webpackChunkName: "locale__vercel_path0_i18n_language_de_ts" */), cache: true }],
  "fr": [{ key: "../i18n/language/fr.ts", load: () => import("../i18n/language/fr.ts" /* webpackChunkName: "locale__vercel_path0_i18n_language_fr_ts" */), cache: true }],
  "es": [{ key: "../i18n/language/es.ts", load: () => import("../i18n/language/es.ts" /* webpackChunkName: "locale__vercel_path0_i18n_language_es_ts" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../i18n/vue-i18n.ts?hash=e45289f8&config=1" /* webpackChunkName: "vue_i18n_ts_e45289f8" */)
]

export const nuxtI18nOptions = {
  "restructureDir": "i18n",
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false,
    "typedPages": true,
    "typedOptionsAndMessages": false,
    "generatedLocaleFilePathFormat": "absolute",
    "alternateLinkCanonicalQueries": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false,
    "optimizeTranslationDirective": true
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "vue-i18n.ts",
  "locales": [
    {
      "code": "en",
      "files": [
        "/vercel/path0/i18n/language/en.ts"
      ]
    },
    {
      "code": "nl",
      "files": [
        "/vercel/path0/i18n/language/nl.ts"
      ]
    },
    {
      "code": "de",
      "files": [
        "/vercel/path0/i18n/language/de.ts"
      ]
    },
    {
      "code": "fr",
      "files": [
        "/vercel/path0/i18n/language/fr.ts"
      ]
    },
    {
      "code": "es",
      "files": [
        "/vercel/path0/i18n/language/es.ts"
      ]
    }
  ],
  "defaultLocale": "en",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "prefix_except_default",
  "lazy": true,
  "langDir": "language/",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "customRoutes": "config",
  "pages": {
    "contact": {
      "en": "/contact",
      "de": "/kontakt",
      "nl": "/contact",
      "fr": "/contact",
      "es": "/contacto"
    },
    "contact-success": {
      "en": "/contact-success",
      "de": "/contact-success",
      "nl": "/contact-success",
      "fr": "/contact-success",
      "es": "/contact-success"
    },
    "events/index": {
      "en": "/events",
      "de": "/veranstaltungen",
      "nl": "/evenementen",
      "fr": "/evenements",
      "es": "/eventos"
    },
    "events/[item]": {
      "en": "/events/[item]",
      "de": "/veranstaltungen/[item]",
      "nl": "/evenementen/[item]",
      "fr": "/evenements/[item]",
      "es": "/eventos/[item]"
    },
    "faq": {
      "en": "/faq",
      "de": "/faq",
      "nl": "/faq",
      "fr": "/faq",
      "es": "/faq"
    },
    "gallery": {
      "en": "/gallery",
      "de": "/galerie",
      "nl": "/galerij",
      "fr": "/galerie",
      "es": "/galeria"
    },
    "highlights": {
      "en": "/highlights",
      "de": "/sehenswurdigkeiten",
      "nl": "/bezienswaardigheden",
      "fr": "/attractions",
      "es": "/atracciones"
    },
    "index": {
      "en": "/",
      "de": "/",
      "nl": "/",
      "fr": "/",
      "es": "/"
    },
    "meetingform": {
      "en": "/meeting-form",
      "de": "/besprechungsformular",
      "nl": "/vergaderformulier",
      "fr": "/formulaire-de-reunion",
      "es": "/forma-de-reunion"
    },
    "meetingform-success": {
      "en": "/meeting-success",
      "de": "/meeting-success",
      "nl": "/meeting-success",
      "fr": "/meeting-success",
      "es": "/meeting-success"
    },
    "meetingpackages/index": {
      "en": "/meeting-packages",
      "de": "/tagungspakete",
      "nl": "/vergaderarrangementen",
      "fr": "/forfaits-de-reunion",
      "es": "/paquetes-de-reunion"
    },
    "meetingpackages/[item]": {
      "en": "/meeting-packages/[item]",
      "de": "/tagungspakete/[item]",
      "nl": "/vergaderarrangementen/[item]",
      "fr": "/forfaits-de-reunion/[item]",
      "es": "/paquetes-de-reunion/[item]"
    },
    "meetingrooms": {
      "en": "/meeting-rooms",
      "de": "/tagungsraume",
      "nl": "/vergaderzalen",
      "fr": "/salles-de-reunion",
      "es": "/salas-de-reuniones"
    },
    "news/index": {
      "en": "/news",
      "de": "/nachrichten",
      "nl": "/nieuws",
      "fr": "/actualites",
      "es": "/noticias"
    },
    "news/[item]": {
      "en": "/news/[item]",
      "de": "/nachrichten/[item]",
      "nl": "/nieuws/[item]",
      "fr": "/actualites/[item]",
      "es": "/noticias/[item]"
    },
    "packages/index": {
      "en": "/special-offers",
      "de": "/arrangements",
      "nl": "/arrangementen",
      "fr": "/special-offers",
      "es": "/special-offers"
    },
    "packages/[item]": {
      "en": "/special-offers/[item]",
      "de": "/arrangements/[item]",
      "nl": "/arrangementen/[item]",
      "fr": "/special-offers/[item]",
      "es": "/special-offers/[item]"
    },
    "privacy": {
      "en": "/privacy-cookies",
      "de": "/datenschutz-cookies",
      "nl": "/privacy-cookies",
      "fr": "/confidentialite-cookies",
      "es": "/privacidad-cookies"
    },
    "reviews": {
      "en": "/reviews",
      "de": "/rezensionen",
      "nl": "/beoordelingen",
      "fr": "/revues",
      "es": "/resenas"
    },
    "rooms/index": {
      "en": "/rooms",
      "de": "/zimmer",
      "nl": "/kamers",
      "fr": "/chambres",
      "es": "/habitaciones"
    },
    "rooms/[item]": {
      "en": "/rooms/[item]",
      "de": "/zimmer/[item]",
      "nl": "/kamers/[item]",
      "fr": "/chambres/[item]",
      "es": "/habitaciones/[item]"
    },
    "sitemap": {
      "en": "/sitemap",
      "de": "/sitemap",
      "nl": "/sitemap",
      "fr": "/sitemap",
      "es": "/sitemap"
    }
  },
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "multiDomainLocales": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "en",
    "files": [
      {
        "path": "/vercel/path0/i18n/language/en.ts"
      }
    ]
  },
  {
    "code": "nl",
    "files": [
      {
        "path": "/vercel/path0/i18n/language/nl.ts"
      }
    ]
  },
  {
    "code": "de",
    "files": [
      {
        "path": "/vercel/path0/i18n/language/de.ts"
      }
    ]
  },
  {
    "code": "fr",
    "files": [
      {
        "path": "/vercel/path0/i18n/language/fr.ts"
      }
    ]
  },
  {
    "code": "es",
    "files": [
      {
        "path": "/vercel/path0/i18n/language/es.ts"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false
export const hasPages = true

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18nInternal"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
